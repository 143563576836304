import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"

// import { Card } from '../components/card';

export default (...args) => {
  return (
    <Layout>
      <SEO title="Experienced Dental Professionals &amp; Exemplary Care" />
    </Layout>
  )
}
